<script>
import { vehicleService } from "../../../helpers/backend/vehicle.service";
import { warehouseService } from "../../../helpers/backend/warehouse.service";
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        vehicle_number: "",
        warehouse_id: null,
      },
      warehouse_options: [{ text: "Select One", value: null }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created(){
    this.fetchWarehouse()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      vehicleService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Vehicle Type. Error : " + error;
          this.busy = false;
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.name = "";
      this.form.vehicle_number = "";
      this.form.warehouse_id = null;

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        vehicleService.getById(id).then(
          (data) => {
            if (data.vehicle != null) {
              this.form.id = data.vehicle.id;
              this.form.name = data.vehicle.name;
              this.form.vehicle_number = data.vehicle.vehicle_number;
              this.form.warehouse_id = data.vehicle.warehouse_id;
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get vehicle type to update. Error : vehicle not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get vehicle type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchWarehouse(){
      warehouseService.getAll().then(
        (data) => {
          if (data.warehouses != null) {
            this.warehouse_options = this.warehouse_options.concat(
              data.warehouses.map(function (mType) {
                return { text: mType.name, value: mType.id };
              })
            );
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get warehouse. Error : warehouse not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error =
            "Failed to get warehouse. Error : " + err;
          this.busy = false;
        }
      );
    }
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Vehicle Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Vehicle Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Vehicle Number :"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.vehicle_number"
                  type="text"
                  placeholder="Vehicle Number"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Warehouse: "
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.warehouse_id"
                  :options="warehouse_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



