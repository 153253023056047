import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const vehicleService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
}

function getById(machineTypeId) {
    const requestOptions = {
        url: '/api/v1/user/vehicle/' + machineTypeId,
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll() {
    // console.log(process.env)
    const requestOptions = {
        url: '/api/v1/user/vehicle',
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {

    let formData = new FormData()

    for (var key in jsonData) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key])
    }
    let rMethod = jsonData["id"] != null ? "PUT" : "POST"
    const requestOptions = {
        url: '/api/v1/user/vehicle',
        method: rMethod,
        data: formData,
        headers: authHeader()
    }

    return axiosInstance(requestOptions)
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/vehicle/` + id,
        method: 'DELETE',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

